import { useState, useContext, useEffect } from "preact/hooks";
import { createContext, Component } from "preact";
import { ProgressiveRequest } from "../ProgressiveRequest.tsx";
import { getServer } from '../Server.tsx';
import { SessionContext } from "../SessionContext.tsx";
import { ActivityShiftContent } from "./Shift.tsx";
import IconDice1 from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/dice-1.tsx";
import IconDice4 from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/dice-4.tsx";
import IconDice5 from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/dice-5.tsx";
import IconChevronLeft from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/chevron-left.tsx";
import IconChevronRight from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/chevron-right.tsx";
import IconAirBalloon from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/air-balloon.tsx";

export function ActivityContent({ back, args }){

	return (
		<div>
			<_ActivityContent back={back} args={args}/>
		</div>
	);
}

class _ActivityContent extends Component {
	static contextType = SessionContext;
	
	state = {
		loading: true,
		info: {},
		selectedItem: null
	};

	_state = {
		loading: true,
		info: {},
		selectedItem: null
	};

	customSetState(o){
		this.setState(o);
		let nwState = {};
		for(let prop in this._state){
			nwState[prop] = this._state[prop];
		}
		for(let prop in o){
			nwState[prop] = o[prop];
		}
		this._state = nwState;
	}
	
	componentDidMount() {
		let that = this;

		this.load();
	}

	isLoading = false;
	load(){
		let that = this;
		let session = this.context;

		if(this.isLoading){
			return;
		}

		this.isLoading = true;
		ProgressiveRequest({
			url: getServer()+'/api/front/activity',
			method: 'post',
			data: {
				action: 'getContent',
				session: session
			},
			receiving: function(data){

				console.log('receiving matching');
				console.log(data);

				if(data.init){
					that.customSetState({
						info: data.info
					});
				}else{
					let nwItems = {};
					for(let prop in that._state.info){
						nwItems[prop] = that._state.info[prop];
					}
					for(let prop in data.info){
						nwItems[prop] = data.info[prop];
					}
					that.customSetState({
						info: nwItems
					});
				}

			},
			received: function(){
				console.log('done');
				that.isLoading = false;
			}
		});
	}

	importladdition(){
		let that = this;
		let session = this.context;

		ProgressiveRequest({
			url: getServer()+'/api/front/activity',
			method: 'post',
			data: {
				action: 'importladdition',
				session: session
			},
			receiving: function(data){

				console.log('receiving matching');
				console.log(data);

			},
			received: function(){
				console.log('done');
				that.isLoading = false;
			}
		});
	}

	render() {
		let that = this;

		const ListItem = function({ item }){
			return <div class="border-top px-3 py-2" onClick={()=>{that.customSetState({
				selectedItem: item
			});}}>
				<div class="d-flex align-items-center justify-content-between">
					<div>
						<div class="d-flex align-items-center mb-0">
							<span class="fs-5">{item.date} {item.hour}</span>
						</div>
						<div>
							{item.attributes?
								<span class="">{item.attributes.evat?<span class="badge rounded-pill bg-light text-dark me-2">HT: {item.attributes.evat.toFixed(2)}€</span>:null} {item.attributes.tax?<span class="badge rounded-pill bg-light text-dark me-2">TVA: {item.attributes.tax.toFixed(2)}€</span>:null}</span>
								:null
							}
						</div>
					</div>
					<div><span class="ms-2"><b>{item.attributes.evat.toFixed(2)}€</b></span>
					<div class="p-2 d-inline-block align-middle">
						<IconChevronRight class="w-6 h-6 arrowc" />
					</div></div>
				</div>
			</div>;
		};

		if(that._state.selectedItem){

			return (
				<ActivityShiftContent item={that._state.selectedItem} back={()=>{that.customSetState({selectedItem: null});}} />
			);
			
		}else{
			return (
				<div>
					<div class="d-flex align-items-center mt-2 mb-4">
						{that.props.back?
							<div class="p-1 rounded-pill text-white me-4 arrowb" onClick={that.props.back}>
								<IconChevronLeft class="w-6 h-6" />
							</div>:
							<a class="p-1 rounded-pill text-white me-4 arrowb" href="/">
								<IconChevronLeft class="w-6 h-6" />
							</a>
						}
						<h1 class="d-flex align-items-center h3 mb-0">
							<IconAirBalloon class="w-6 h-6" /><span class="ms-2">Les données de vente</span>
						</h1>
					</div>
					<div class="my-4">
						<div class="card" style="background-color:rgb(235 236 240)">
							<div class="card-body">
								<div class="h3 titleb">La synchronisation</div>
								<div>L'ensemble des données de vente de vos outils sont synchronisées et sauvegardées sur le cloud. Dès la clôture d'un service, les données sont envoyées, consolidées et rendues disponibles sur votre back-office.</div>
							</div>
						</div>
					</div>
					<div class="my-4">
						<div class="d-flex align-items-center justify-content-between mb-1">
							<h2 class="h4 d-flex align-items-center mb-0">
								<IconDice5 class="w-6 h-6 me-2" /><span>FDJ</span>
							</h2>
						</div>
						<div class="card">
							<div class="card-body p-0 titleb">
								<div class="list-group border-0">
									{that._state.info.fdj?
										(that._state.info.fdj.length == 0?
											<div class="list-group-item border-0 rounded-1 py-2">
												<div class="d-flex align-items-center">
													<span class="px-4">-</span>
												</div>
											</div>:
											that._state.info.fdj.map((item)=>{
												return <ListItem item={item} />;
											})
										)
									:null}
								</div>
							</div>
						</div>
					</div>
					<div class="my-4">
						<div class="d-flex align-items-center justify-content-between mb-1">
							<h2 class="h4 d-flex align-items-center mb-0">
								<IconDice1 class="w-6 h-6 me-2" /><span>Aleda</span>
							</h2>
						</div>
						<div class="card">
							<div class="card-body p-0 titleb">
								<div class="list-group border-0">
									{that._state.info.aleda?
										(that._state.info.aleda.length == 0?
											<div class="list-group-item border-0 rounded-1 py-2">
												<div class="d-flex align-items-center">
													<span>-</span>
												</div>
											</div>:
											that._state.info.aleda.map((item)=>{
												return <ListItem item={item} />;;
											})
										)
									:null}
								</div>
							</div>
						</div>
					</div>
					<div class="my-4">
						<div class="d-flex align-items-center justify-content-between mb-1">
							<h2 class="h4 d-flex align-items-center mb-0">
								<IconDice4 class="w-6 h-6 me-2" /><span>L'Addition</span>
							</h2>
							<button type="button" class="btn btn-light bg-white rounded-pill d-flex align-items-center btn-sm px-3"  onClick={()=>{that.importladdition();}}>importer</button>
						</div>
						<div class="card">
							<div class="card-body p-0 titleb">
								<div class="list-group border-0">
									{that._state.info.laddition?
										(that._state.info.laddition.length == 0?
											<div class="border-0 py-2">
												<div class="d-flex align-items-center">
													<span>-</span>
												</div>
											</div>:
											that._state.info.laddition.map((item)=>{
												return <ListItem item={item} />;
											})
										)
									:null}
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}