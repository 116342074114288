import { useState, useContext, useEffect } from "preact/hooks";
import { createContext, Component } from "preact";
import { ProgressiveRequest } from "../ProgressiveRequest.tsx";
import { getServer } from '../Server.tsx';
import { SessionContext } from "../SessionContext.tsx";
import { ActivityShiftContent } from "./Shift.tsx";
import IconDice1 from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/dice-1.tsx";
import IconDice4 from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/dice-4.tsx";
import IconDice5 from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/dice-5.tsx";
import IconChevronLeft from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/chevron-left.tsx";
import IconChevronRight from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/chevron-right.tsx";
import IconAirBalloon from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/air-balloon.tsx";

export function ActivityShiftAccountingContent({ back, args }){

	return (
		<div>
			<_ActivityShiftAccountingContent back={back} args={args}/>
		</div>
	);
}

class _ActivityShiftAccountingContent extends Component {
	static contextType = SessionContext;
	
	state = {
		loading: true,
		info: {},
		selectedItem: null
	};

	_state = {
		loading: true,
		info: {},
		selectedItem: null
	};

	customSetState(o){

		this.setState(o);
		let nwState = {};
		for(let prop in this._state){
			nwState[prop] = this._state[prop];
		}
		for(let prop in o){
			nwState[prop] = o[prop];
		}
		this._state = nwState;
	}
	
	componentDidMount() {
		let that = this;

		this.load();
	}

	isLoading = false;
	load(){
		let that = this;
		let session = this.context;

		if(this.isLoading){
			return;
		}

		this.isLoading = true;
		ProgressiveRequest({
			url: getServer()+'/api/front/activity',
			method: 'post',
			data: {
				action: 'getShiftAccountingContent',
				session: session,
				item: that.props.args.item,
				shift: that.props.args.shift
			},
			receiving: function(data){

				console.log('receiving matching');
				console.log(data);

				if(data.init){
					that.customSetState({
						info: data.info
					});
				}else{
					let nwItems = {};
					for(let prop in that._state.info){
						nwItems[prop] = that._state.info[prop];
					}
					for(let prop in data.info){
						nwItems[prop] = data.info[prop];
					}
					that.customSetState({
						info: nwItems
					});
				}

			},
			received: function(){
				console.log('done');
				that.isLoading = false;
			}
		});
	}

	render() {
		let that = this;

		const ListItem = function({ item }){
			return <div key={"item-"+item.id} class="list-group-item border-0 rounded-1 list-group-item-action py-2">
				<div class="d-flex align-items-center justify-content-between">
					<div>
						<div class="d-flex align-items-center mb-0">
							<span class="fs-5">{item.label}</span>
						</div>
						<div>
							<span class="ps-1">{item.nb} éléments</span>
						</div>
					</div>
					<div class="p-2 rounded-pill bg-light">
						{item.amount}€
					</div>
				</div>
			</div>;
		};

		if(that._state.selectedItem){

			return (
				<ActivityShiftContent item={that._state.selectedItem} back={()=>{that.customSetState({selectedItem: null});}} />
			);
			
		}else{

			return (
				<div>
					<div class="d-flex align-items-center mt-2 mb-4">
						<div class="p-1 rounded-pill text-white me-4 arrowb" onClick={that.props.back}>
							<IconChevronLeft class="w-6 h-6" />
						</div>
						<h1 class="d-flex align-items-center h3 mb-0">
							<IconAirBalloon class="w-6 h-6" /><span class="ms-2">Les données de vente</span>
						</h1>
					</div>
					<div class="my-4">
						<div class="d-flex align-items-center justify-content-between mb-1">
							<h2 class="h4 d-flex align-items-center mb-0">
								<IconDice1 class="w-6 h-6 me-2" /><span>{this.props.args.item.name || this.props.args.item.label}</span>
							</h2>
						</div>
						<div class="card">
							<div class="card-body">
								<div class="list-group border-0">
									<div key={"item-total"} class="list-group-item border-0 rounded-1 py-2">
										<div class="d-flex align-items-center justify-content-between">
											<div>
												<div class="d-flex align-items-center mb-0">
													<span class="fs-5 fw-bolder">Total</span>
												</div>
												<div>
													<span class="ps-1">
														{
															that._state.info.totals?
																<span class="bg-light px-2 py-1 rounded-2">{that._state.info.totals.nb} élément{that._state.info.totals.nb > 1?'s':''}</span>
																:<div class="sk_bg" style={{width: '90%'}}>&nbsp;</div>
														}
													</span>
												</div>
											</div>
											<div class="p-2 fw-bolder">
												{
													that._state.info.totals?
														<span class="bg-light px-2 py-1 rounded-2">{that._state.info.totals.amount}€</span>
														:<div class="sk_bg" style={{width: '90%'}}>&nbsp;</div>
												}
											</div>
										</div>
									</div>
									{that._state.info.lines?
										(that._state.info.lines.length == 0?
											<div class="list-group-item border-0 rounded-1 py-2">
												<div class="d-flex align-items-center">
													<span>-</span>
												</div>
											</div>:
											that._state.info.lines.map((item)=>{
												return <ListItem item={item} />;;
											})
										)
									:null}
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}