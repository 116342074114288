import { useState, useContext, useEffect } from "preact/hooks";
import { createContext, Component } from "preact";
import { ProgressiveRequest } from "../ProgressiveRequest.tsx";
import { getServer } from '../Server.tsx';
import { SessionContext } from "../SessionContext.tsx";
import { ActivityShiftAccountingContent } from "./ShiftAccounting.tsx";
import IconChevronLeft from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/chevron-left.tsx";
import IconChevronRight from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/chevron-right.tsx";
import IconAirBalloon from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/air-balloon.tsx";
import IconBook2 from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/book-2.tsx";
import { ResponsiveContainer, XAxis, Tooltip,
	BarChart, Bar,
} from 'recharts';

export function ActivityShiftContent({ back, item }){

	return (
		<div>
			<_ActivityShiftContent back={back} item={item}/>
		</div>
	);
}

class _ActivityShiftContent extends Component {
	static contextType = SessionContext;
	
	state = {
		loading: true,
		info: {},
		selectedItem: null
	};

	_state = {
		loading: true,
		info: {},
		selectedItem: null
	};

	customSetState(o){
		this.setState(o);
		let nwState = {};
		for(let prop in this._state){
			nwState[prop] = this._state[prop];
		}
		for(let prop in o){
			nwState[prop] = o[prop];
		}
		this._state = nwState;
	}
	
	conf = {
		service: 'laddition',//this.props.service,
		title: '',
		description: null,
		genericItemTypes: [],
		accounts: [
			{
				identifier: 'accounting.sell',
				refNb: 'nbSellAccounts',
				label: 'Les ventes'
			},
			{
				identifier: 'accounting.tax',
				refNb: 'nbTaxAccounts',
				label: 'Les taxes'
			},
			{
				identifier: 'accounting.payment',
				refNb: 'nbPayAccounts',
				label: 'Les paiements'
			},
			{
				identifier: 'accounting.others',
				refNb: 'nbOthers',
				label: 'Divers'
			}
		]
	};

	componentDidMount() {
		let that = this;

		switch(this.conf.service){
			case 'fdj':
				const fdj = {
					id: 3,
					code: 'fdj',
					name: 'FDJ'
				};
				this.conf.title = fdj.name;
				this.conf.genericItemTypes = [
					{
						name: 'Les jeux',
						mandatory: false,
						genericType: 'product',
						category: 'ventes',
						service: fdj
					}
				];
			break;
			case 'aleda':
				const aleda = {
					id: 1,
					code: 'aleda',
					name: 'Aleda'
				};
				this.conf.title = aleda.name;
				this.conf.description = `Les chiffres du service sont ventillés selon les catégories Aleda et également par comptes comptables.`;
				this.conf.genericItemTypes = [
					{
						name: 'Les familles',
						mandatory: true,
						genericType: 'category',
						category: 'ventes',
						service: aleda
					},
					{
						name: 'Les TVA',
						mandatory: true,
						genericType: 'tax',
						category: 'taxes',
						service: aleda
					},
					{
						name: 'Les moyens de paiement',
						mandatory: true,
						genericType: 'payment_type',
						category: 'paiements',
						service: aleda
					},
					{
						name: 'Les produits',
						mandatory: false,
						genericType: 'product',
						category: 'ventes',
						service: aleda
					}
				];
			break;
			case 'laddition':
				const laddition = {
					id: 2,
					code: 'laddition',
					name: 'L\'Addition'
				};
				this.conf.title = laddition.name;
				this.conf.description = `Les chiffres du service sont ventillés selon les entités L'Addition et également par comptes comptables.`;
				this.conf.genericItemTypes = [
					{
						name: 'Les types de produits',
						mandatory: true,
						genericType: 'product_type',
						category: 'ventes',
						service: laddition
					},
					{
						name: 'Les TVA',
						mandatory: true,
						genericType: 'tax',
						category: 'taxes',
						service: laddition
					},
					{
						name: 'Les moyens de paiement',
						mandatory: true,
						genericType: 'payment_type',
						category: 'paiements',
						service: laddition
					},
					{
						name: 'Les produits',
						mandatory: false,
						genericType: 'product',
						category: 'ventes',
						service: laddition
					}
				];
			break;
		}
		this.load();
	}

	isLoading = false;
	load(){
		let that = this;
		let session = this.context;

		if(this.isLoading){
			return;
		}

		this.isLoading = true;
		ProgressiveRequest({
			url: getServer()+'/api/front/activity',
			method: 'post',
			data: {
				action: 'getShift',
				id: that.props.item.id,
				types: {
					genericTypes: this.conf.genericItemTypes.map((item)=>{return item.genericType;}),
					accounting: this.conf.accounts.map((item)=>{return item.identifier;})
				}
			},
			receiving: function(data){

				console.log('receiving matching');
				console.log(data);

				if(data.init){
					that.customSetState({
						info: data.info
					});
				}else{
					let nwItems = {};
					for(let prop in that._state.info){
						nwItems[prop] = that._state.info[prop];
					}
					for(let prop in data.info){
						nwItems[prop] = data.info[prop];
					}
					that.customSetState({
						info: nwItems
					});
				}

			},
			received: function(){
				console.log('done');
				that.isLoading = false;
			}
		});
	}

	select(item){
		this.customSetState({selectedItem: item});
	}

	render() {
		let that = this;

		if(that._state.selectedItem){

			return <ActivityShiftAccountingContent args={{item: that._state.selectedItem, shift: {id: that.props.item.id}}} back={()=>{that.customSetState({selectedItem: null});}} />;
			
		}else{

			return (
				<div>
					<div class="d-flex align-items-center mt-2 mb-4">
						{that.props.back?
							<div class="p-1 rounded-pill text-white me-4 arrowb" onClick={that.props.back}>
								<IconChevronLeft class="w-6 h-6" />
							</div>:
							<a class="p-1 rounded-pill text-white me-4 arrowb" href="/">
								<IconChevronLeft class="w-6 h-6" />
							</a>
						}
						<h1 class="d-flex align-items-center h3 mb-0">
							<IconAirBalloon class="w-6 h-6" /><span class="ms-2">Le service</span>
						</h1>
					</div>
					<div class="my-4">
						<h2 class="mt-3 d-flex align-items-center"><IconBook2 class="w-6 h-6 me-2" />Le 23/05/2023 à 19h20</h2>
						<div class="row">
							<div class="col">
								<div class="card my-2">
									<div class="card-body">
										<div class="d-flex align-items-center justify-content-center text-center">
											<div>
												<div><span class="fw-6">CA</span></div>
												<div>{this._state.info.shift && this._state.info.shift.data && this._state.info.shift.data.ca !== null?<span class="h3">{this._state.info.shift.data.ca}€</span>:<div class="sk_bg" style={{width: '90%'}}>&nbsp;</div>}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col">
								<div class="card my-2">
									<div class="card-body">
										<div class="d-flex align-items-center justify-content-center text-center">
											<div>
												<div><span class="fw-6">Panier moyen</span></div>
												<div>{this._state.info.shift && this._state.info.shift.data && this._state.info.shift.data.avgcard !== null?<span class="h3">{this._state.info.shift.data.avgcard}€</span>:<div class="sk_bg" style={{width: '90%'}}>&nbsp;</div>}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="card my-2">
							<div class="card-body">
								{this._state.info.chart && this._state.info.chart.data ?
								<ResponsiveContainer width="100%" height={200}>
							{this._state.info.chart.data.hours.length > 0 ?
								<BarChart data={this._state.info.chart.data.hours}>
									<Bar dataKey="amount" fill="rgba(153, 102, 255, 0.4)" radius={[10, 10, 0, 0]} barSize={10} />
									<XAxis dataKey="label" axisLine={true} strokeWidth={0.3} tickLine={false} style={{fontSize: '0.7rem'}} />
									<Tooltip cursor={{ fill: "rgb(204 204 204 / 75%)" }} />
								</BarChart>
								: <p style="top: 50%; left: 50%; transform: translate(-50%, -50%); position: absolute;" >Pas assez de données disponibles</p>
								}
								</ResponsiveContainer>
								:<div>
									<div class="spinner-border text-secondary" role="status">
										<span class="visually-hidden">Loading...</span>
									</div>
								</div>
							}
							</div>
						</div>
						{this.conf.description?
							<div class="card my-2">
								<div class="card-body">
								{this.conf.description}
								</div>
							</div>
							:null}
						<div class="">
							<h3 class="mt-3 d-flex align-items-center h4"><IconBook2 class="w-6 h-6 me-2" />Les entités L'Addition</h3>
							{this.conf.genericItemTypes.map((type)=>{
								return (
									<div class="card my-2" onClick={()=>{that.select(type);}}>
										<div class="card-body">
											<div class="d-flex align-items-center justify-content-between">
												<div>
													<h2 class="d-flex align-items-center h4 mb-0">
														<span class="">{type.name}</span>
													</h2>
													<div>
														{this._state.info.genericTypes && this._state.info.genericTypes.data && this._state.info.genericTypes.data[type.genericType]?<span class="badge rounded-pill bg-light text-dark me-2">{this._state.info.genericTypes.data[type.genericType].nb} élément{this._state.info.genericTypes.data[type.genericType].nb > 1?'s':''}</span>:<div class="sk_bg" style={{width: '90%'}}>&nbsp;</div>}
														{this._state.info.genericTypes && this._state.info.genericTypes.data && this._state.info.genericTypes.data[type.genericType]?<span class="badge rounded-pill bg-info text-dark me-2">{this._state.info.genericTypes.data[type.genericType].amount}€</span>:<div class="sk_bg" style={{width: '90%'}}>&nbsp;</div>}
														<span class="badge rounded-pill bg-light text-dark me-2">{type.category}</span>
													</div>
												</div>
												<div class="p-2 rounded-pill bg-light">
													<IconChevronRight class="w-6 h-6" />
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</div>
						<div class="">
							<h3 class="mt-3 d-flex align-items-center h4"><IconBook2 class="w-6 h-6 me-2" />Les comptes comptables</h3>
							{this.conf.accounts.map((item)=>{
								return <div class="card my-2" onClick={()=>{that.select(item);}}>
									<div class="card-body">
										<div class="d-flex align-items-center justify-content-between">
											<div>
												<h2 class="my-2 h5"><span class="ms-2">{item.label}</span></h2>
												<div>
													{this._state.info.accounting && this._state.info.accounting.data && this._state.info.accounting.data[item.refNb]?<span class="badge rounded-pill bg-light text-dark me-2">{this._state.info.accounting.data[item.refNb].nb} élément{this._state.info.accounting.data[item.refNb].nb > 1?'s':''}</span>:<div class="sk_bg" style={{width: '90%'}}>&nbsp;</div>}
													{this._state.info.accounting && this._state.info.accounting.data && this._state.info.accounting.data[item.refNb]?<span class="badge rounded-pill bg-info text-dark me-2">{this._state.info.accounting.data[item.refNb].amount}€</span>:<div class="sk_bg" style={{width: '90%'}}>&nbsp;</div>}
												</div>
											</div>
											<IconChevronRight class="w-6 h-6" />
										</div>
									</div>
								</div>;
							})}
						</div>
					</div>
				</div>
			);
		}
	}
}